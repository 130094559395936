import {Component, OnInit} from '@angular/core';

class Catalog {
  name;
  date;
  size;
  link;

  public constructor(name, date, size, link) {
    this.name = name;
    this.date = date;
    this.size = size;
    this.link = link;
  }
}

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  catalogs: Catalog[] = [
    new Catalog(
        'ESAB Akciós Caddy tig AC/DC csomagok', '2017.4.24.', '500.2 KB',
        'is_promotion_2017_q2-10_hub_caddy_tig_acdc-szerk_6t2s.pdf'),
    new Catalog(
        'ESAB Akciós Caddy Tig DC csomagok', '2017.4.24.', '565.24 KB',
        'is_promotion_2017_q2-9_hub_caddy_tig_dc-szerk_jf4i.pdf'),
    new Catalog(
        'ESAB Hegesztő és plazmavágó gépek', '2017.1.10.', '7.05 MB',
        '7.hegeszto-es-plazmavago-gepek_a0zs.pdf'),
    new Catalog(
        'ESAB Rebel', '2016.10.12.', '2.1 MB',
        'xa00184313_esab_rebel_brochure_a4_hungary_small-(3)_cqc6.pdf'),
    new Catalog(
        'ESAB hegesztőanyagok kézikönyve', '2016.10.12.', '21.96 MB',
        'esab-hegesztoanyagok-kezikonyve-2016_l_xj.pdf'),
    new Catalog(
        'ORIGO MIG C PRO sorozat', '2016.10.7.', '584.61 KB',
        'is_promotion_2016_q4-2_hub_ju_j.pdf'),
    new Catalog(
        'ESAB RAILTRAC 1000', '2016.6.27.', '2.13 MB',
        'railtrac-1000_bros_hun_web_d7xg.pdf'),
    new Catalog(
        'ESAB hegesztők kézikönyve', '2016.3.15.', '533.39 KB',
        'esab-hegesztok-kezikonyve_cuew.pdf'),
    new Catalog(
        'ESAB hegesztőfelszerelések és kellékek', '2014.10.24.', '6.71 MB',
        'hegesztofelszerelesek-es-kellekek-2012_avvu.pdf'),
    new Catalog(
        'ESAB Best Seller hegesztőanyagok', '2014.11.17.', '3.27 MB',
        'best-seller-hegesztoanyagok-2012_zau9.pdf'),
    new Catalog(
        'Hegesztőanyag tárolás és kezelés', '2014.11.19.', '4.05 MB',
        'hegeszto-anyag-tarolas-es-kezeles-kezikonyvkategoria_6j2u.pdf'),
    new Catalog(
        'ESAB PSF250 MIG pisztoly kopó alkatrészek', '2015.1.20.', '341.27 KB',
        'psf-hegeszto-pisztolyok-250_sd70.pdf'),
    new Catalog(
        'ESAB Automation Catalogue', '2018.10.11.', '1.44 MB',
        'esab-automation-catalogue_h_7a.pdf'),
    new Catalog(
        'ESAB Maraton Pac előnyei', '2018.4.15.', '1.36 MB',
        'maraton-pac_88qy.pdf'),
    new Catalog(
        'ESAB Renegda akció', '2017.11.11.', '2.07 MB',
        'renegade-promocio-profil-motor_a2e8.pdf'),
    new Catalog(
        'ESAB Rebel tájékoztató', '2017.11.11.', '2.07 MB',
        'xa00184413-rebel-takeone-eu-hu-(2)_8ci5.pdf'),
    new Catalog(
        '7 Hegesztő Aristo Mig 4004i Pulse', '2017.11.3.', '2.07 MB',
        '7-hegeszto-aristo-mig-4004i-pulse_yg0o.pdf'),
    new Catalog(
        '7.Hegesztő és plazmavágó gépek', '2017.11.3.', '7.05 MB',
        '7.hegeszto-es-plazmavago-gepek_8ar3.pdf'),
  ];

  constructor() {}

  ngOnInit() {}
}
