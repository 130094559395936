import {Component, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {GlobalService} from '../../global.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements AfterViewInit {
  constructor(public global: GlobalService /* global used in the HTML*/) {}

  isMenuOpen = false;

  @ViewChild('nes', {static: true}) sentinelRef: ElementRef;
  @ViewChild('neh', {static: true}) headerRef: ElementRef;
  @ViewChild('nt', {static: true}) navbarTitleRef: ElementRef;
  @ViewChild('altlogo', {static: true}) altLogoRef: ElementRef;
  @ViewChild('altlogoopposite', {static: true}) altLogoOppositeRef: ElementRef;

  linkClick(e) {
    if (this.isMenuOpen) {
      this.menuToggle();
    }
  }

  navMenuClick() {
    if (this.isMenuOpen) {
      this.menuToggle();
    }
  }

  menuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
    const x = document.getElementById('nav-menu');
    if (x.className === 'closed') {
      x.className = 'open';
      document.body.style.overflowY = 'hidden';
      // document.body.addEventListener('touchmove', function(e) {
      // e.preventDefault(); });
    } else {
      x.className = 'closed';
      document.body.style.overflowY = 'auto';
      // document.body.addEventListener('touchmove', function(e) {});
    }
  }

  ngAfterViewInit() {
    const handler = (entries) => {
      // entries is an array of observed dom nodes
      // we're only interested in the first one at [0]
      // because that's our .sentinal node.
      // Here observe whether or not that node is in the viewport
      if (!entries[0].isIntersecting) {
        this.headerRef.nativeElement.classList.add('enabled');
        this.navbarTitleRef.nativeElement.classList.add('enabled');
        this.altLogoRef.nativeElement.classList.add('enabled');
        this.altLogoOppositeRef.nativeElement.classList.add('enabled');
      } else {
        this.headerRef.nativeElement.classList.remove('enabled');
        this.navbarTitleRef.nativeElement.classList.remove('enabled');
        this.altLogoRef.nativeElement.classList.remove('enabled');
        this.altLogoOppositeRef.nativeElement.classList.remove('enabled');
      }
    };

    const observer = new IntersectionObserver(handler);
    observer.observe(this.sentinelRef.nativeElement);
  }
}
