import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ItemsComponent } from './pages/items/items.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NotFoundComponent } from './not-found.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

const appRoutes: Routes = [
  // { path: 'crisis-center', component: CrisisListComponent },
  // { path: 'hero/:id',      component: HeroDetailComponent },
  // {
  //   path: 'heroes',
  //   component: HeroListComponent,
  //   data: { title: 'Heroes List' }
  // },
  // This is the default home page
  // { path: '',
  //   redirectTo: '/heroes',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: HomeComponent,
    data: { animation: 'HomePage' },
  },
  {
    path: 'termekek',
    component: ItemsComponent,
    data: { animation: 'FilterPage' },
  },
  {
    path: 'bemutatkozas',
    component: AboutComponent,
    data: { animation: 'AboutPage' },
  },
  {
    path: 'katalogusok',
    component: CatalogComponent,
  },
  {
    path: 'kapcsolat',
    component: ContactComponent,
  },
  {
    path: 'adatvedelem',
    component: PrivacyComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports:
    [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
