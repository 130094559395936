import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {CookieService} from 'ngx-cookie-service';

import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {SidebarModule} from 'primeng/sidebar';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {SpinnerModule} from 'primeng/spinner';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {TableModule} from 'primeng/table';

import {ColorPickerModule} from 'ngx-color-picker';

import {GlobalService} from './global.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavComponent} from './pages/nav/nav.component';
import {FooterComponent} from './pages/footer/footer.component';
import {HomeComponent} from './pages/home/home.component';
import {ContactComponent} from './pages/contact/contact.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import {ItemsComponent} from './pages/items/items.component';
import {AboutComponent} from './pages/about/about.component';
import {CatalogComponent} from './pages/catalog/catalog.component';
import {NotFoundComponent} from './not-found.component';
import {TestComponent} from './test/test.component';
import {SmoothHeightComponent} from './smooth-height/smooth-height.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HomeComponent,
    ContactComponent,
    PrivacyComponent,
    ItemsComponent,
    AboutComponent,
    NavComponent,
    FooterComponent,
    TestComponent,
    SmoothHeightComponent,
    CatalogComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    // PrimeNG
    ButtonModule,
    DropdownModule,
    TabViewModule,
    SidebarModule,
    ColorPickerModule,
    InputTextModule,
    CheckboxModule,
    SpinnerModule,
    DynamicDialogModule,
    DialogModule,
    AccordionModule,
    TableModule,
  ],
  providers: [
    CookieService,
    GlobalService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
