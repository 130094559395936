const theme_muted_tones = {
  '--fontFamily': '"Roboto", sans-serif',
  '--navbarTopColor': 'darkcyan',
  '--navbarBottomColor': 'darkcyan',
  '--navActiveLinkColor': '#c0b283',
  '--navLinkColor': '#dcd0c0',
  '--primaryColor': '#f4f4f4',
  '--secondaryColor': '#373737',
  '--textColor': 'white',
  '--bgColor': 'black',
  '--footerBgColor': '#868686',
  '--bgImage': 'url("/assets/img/funky-lines.png")',
};

const theme_asiimov = {
  '--fontFamily': '"Roboto", sans-serif',
  '--footerBgColor': '#ff8c00',
  '--navbarTopColor': '#ff8c00',
  '--navbarBottomColor': '#ff8c00',
  '--navActiveLinkColor': '#ffffff',
  '--navLinkColor': '#e1dede',
  '--primaryColor': '#f4f4f4',
  '--secondaryColor': '#373737',
  '--textColor': 'white',
  '--bgColor': 'black',
  '--bgImage': 'url("/assets/img/funky-lines.png")',
};

const theme_esab = {
  '--fontFamily': '"Roboto", sans-serif',
  '--footerBgColor': '#fff200ff',
  '--navbarTopColor': '#fff200ff',
  '--navbarBottomColor': '#fff200ff',
  '--navActiveLinkColor': '#000000ff',
  // '--navLinkColor': '#6e6e6eff',
  '--navLinkColor': '#4a4a4aff',
  '--primaryColor': '#f4f4f4',
  '--secondaryColor': '#373737',
  '--textColor': 'white',
  '--bgColor': 'black',
  '--bgImage': 'url("/assets/img/funky-lines.png")',
};

const theme_orange = {
  '--fontFamily': '"Roboto", sans-serif',
  '--navActiveLinkColor': '#ff6b02',
  '--navLinkColor': 'orange',
  '--primaryColor': 'lightcyan',
  '--secondaryColor': 'ghostwhite',
  '--textColor': 'white',
  '--bgColor': 'grey',
  // tws.setProperty('--bgImage', 'url("/assets/img/qbkls.png")');
  '--footerBgColor': 'orange',
};

export function theme_override(stylesheet, tw, cs) {
  const tws = tw.style;

  // General Shadowbox
  tws.setProperty('--isShadowbox', 'true');
  tws.setProperty('--sbColor', 'rgba(147, 0, 255, 0.25)');
  tws.setProperty('--sbH', '0');
  tws.setProperty('--sbV', '10px');
  tws.setProperty('--sbBlur', '43px');
  tws.setProperty('--sbSpread', '10px');
  tws.setProperty('--sbInset', 'inset');

  // Nabbar
  tws.setProperty('--navbarAltTitleColor', 'black');
  tws.setProperty('--navbarTitleColor', 'black');
  tws.setProperty('--navbarTitleShadowColor', 'black');

  switch (stylesheet) {
    case 'style1':  // red
      tws.setProperty('--fontFamily', '"Roboto", sans-serif');
      tws.setProperty('--navActiveLinkColor', 'green');
      tws.setProperty('--navLinkColor', 'red');
      tws.setProperty('--primaryColor', 'lightcyan');
      tws.setProperty('--secondaryColor', 'ghostwhite');
      tws.setProperty('--bgColor', 'black');
      // tws.setProperty('--bgImage', 'url("/assets/img/qbkls.png")');
      tws.setProperty('--textColor', 'white');
      tws.setProperty('--footerBgColor', 'green');
      break;
    case 'style2':  // orange
      set_theme(theme_orange, tws);
      break;
    case 'style3':  // purple
      tws.setProperty('--fontFamily', '"Roboto", sans-serif');
      tws.setProperty('--navActiveLinkColor', 'green');
      tws.setProperty('--navLinkColor', 'purple');
      tws.setProperty('--primaryColor', 'lightcyan');
      tws.setProperty('--secondaryColor', 'ghostwhite');
      tws.setProperty('--textColor', 'white');
      tws.setProperty('--bgColor', 'black');
      // tws.setProperty('--bgImage', 'url("/assets/img/qbkls.png")');
      tws.setProperty('--footerBgColor', 'purple');
      break;
    case 'style4':  // Muted Tones
      set_theme(theme_muted_tones, tws);
      break;
    case 'style5':  // Warm and Bold
      tws.setProperty('--fontFamily', '"Roboto", sans-serif');
      tws.setProperty('--navActiveLinkColor', 'red');
      tws.setProperty('--navLinkColor', '#b82601');
      tws.setProperty('--primaryColor', '#062f4f');
      tws.setProperty('--secondaryColor', '#813772');
      tws.setProperty('--textColor', 'white');
      tws.setProperty('--bgColor', 'black');
      // tws.setProperty('--bgImage', 'url("/assets/img/qbkls.png")');
      tws.setProperty('--footerBgColor', '#813772');
      break;
    case 'style6':  // Asiimov Rainmeter
      set_theme(theme_asiimov, tws);
      break;
    case 'style7':  // ESAB
      set_theme(theme_esab, tws);
      break;
    default:
      break;
  }
}

function set_theme(map, tws) {
  Object.keys(map).forEach(key => {
    if (key === '--bgImage') {
      // save bg change to cookie
      // cs.set('bgImg', map[key]);
      // console.log(map[key].substring(17, map[key].length - 6));
    }

    tws.setProperty(key, map[key]);
    // console.log(key + ' => ' + map[key]);
  });
}
