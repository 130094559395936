import {Component, OnInit, Renderer2, ElementRef} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {GlobalService, styleColors, styleNumbers} from './global.service';

import {theme_override} from './theme-override';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './themes/global.light.scss'],
})
export class AppComponent implements OnInit {
  title = 'Nyirweld Kft.';

  styleColorConfigs: Map<string, string> = new Map<string, string>();
  styleNumberConfigs: Map<string, string> = new Map<string, string>();
  theme: string;
  bg: string;

  displayStyleSidebar = false;
  displayThemeSelector = false;
  displayStyleDialog = false;
  displayExport = false;
  showStyleDialog() { this.displayStyleDialog = true; }
  showExportDialog() { this.displayExport = true; }
  showThemeSelector() { this.displayThemeSelector = true; }

  constructor(
      private cookieService: CookieService, public global: GlobalService) {}

  scrollToTop() { window.scroll({top: 0, left: 0, behavior: 'smooth'}); }

  setCookies() {
    if (!this.cookieService.check('isContainer')) {
      this.cookieService.set(
          'isContainer', GlobalService.defaultIsContainer ? 'true' : 'false');
      this.global.isContainer = GlobalService.defaultIsContainer;
    } else {
      const val = this.cookieService.get('isContainer');
      this.global.isContainer = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('isShadowbox')) {
      this.cookieService.set(
          'isShadowbox', GlobalService.defaultIsShadowbox ? 'true' : 'false');
      this.global.isShadowbox = GlobalService.defaultIsShadowbox;
    } else {
      const val = this.cookieService.get('isShadowbox');
      this.global.isShadowbox = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('isShadowboxInset')) {
      this.cookieService.set(
          'isShadowboxInset',
          GlobalService.defaultIsShadowboxInset ? 'true' : 'false');
      this.global.isShadowboxInset = GlobalService.defaultIsShadowboxInset;
    } else {
      const val = this.cookieService.get('isShadowboxInset');
      this.global.isShadowboxInset = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('isNavDecor')) {
      this.cookieService.set(
          'isNavDecor', GlobalService.defaultIsNavDecor ? 'true' : 'false');
      this.global.isNavDecor = GlobalService.defaultIsNavDecor;
    } else {
      const val = this.cookieService.get('isNavDecor');
      this.global.isNavDecor = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('isVidHome')) {
      this.cookieService.set(
          'isVidHome', GlobalService.defaultIsVidHome ? 'true' : 'false');
      this.global.isVidHome = GlobalService.defaultIsVidHome;
    } else {
      const val = this.cookieService.get('isVidHome');
      this.global.isVidHome = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('isColorTransition')) {
      this.cookieService.set(
          'isColorTransition',
          GlobalService.defaultIsColorTransition ? 'true' : 'false');
      this.global.isColorTransition = GlobalService.defaultIsColorTransition;
    } else {
      const val = this.cookieService.get('isColorTransition');
      this.global.isColorTransition = val === 'true' ? true : false;
    }

    if (!this.cookieService.check('style')) {
      this.cookieService.set('style', 'style7');
      this.theme = 'style7';
    } else {
      this.theme = this.cookieService.get('style');
    }
    this.globalOverride(this.theme);

    if (!this.cookieService.check('bgImg')) {
      this.cookieService.set('bgImg', GlobalService.defaultBgImage);
      this.bg = GlobalService.defaultBgImage;
    } else {
      this.bg = this.cookieService.get('bgImg');
    }
  }

  ngOnInit() {
    if (this.global.isDebug) {
      this.setCookies();
    } else {
      this.theme = 'style7';
      // this.bg = GlobalService.defaultBgImage;
      // this.globalOverride(this.theme);
    }

    this.changeBackground(this.bg);
    this.styleUpdate();
  }

  styleUpdate(): void {
    for (let i = 0; i < styleColors.length; ++i) {
      this.styleColorConfigs.set(
          styleColors[i], document.body.style.getPropertyValue(styleColors[i]));
    }
    for (let i = 0; i < styleNumbers.length; ++i) {
      this.styleNumberConfigs.set(
          styleNumbers[i],
          document.body.style.getPropertyValue(styleNumbers[i]));
    }
  }

  onColorTransitionChange(checked): void {
    this.cookieService.set('isColorTransition', checked);
    this.global.isColorTransition = checked;
  }
  onShadowboxInsetChange(checked): void {
    this.cookieService.set('isShadowboxInset', checked);
    this.global.isShadowboxInset = checked;
  }
  onNavDecorChange(checked): void {
    this.cookieService.set('isNavDecor', checked);
    this.global.isNavDecor = checked;
  }
  onContainerChange(checked): void {
    this.cookieService.set('isContainer', checked);
    this.global.isContainer = checked;
  }
  onShadowboxChange(checked): void {
    this.cookieService.set('isShadowbox', checked);
    this.global.isShadowbox = checked;
  }
  onVidHomeChange(checked): void {
    this.cookieService.set('isVidHome', checked);
    this.global.isVidHome = checked;
  }
  onThemeChange(event): void {
    this.cookieService.set('style', event.value);
    this.globalOverride(event.value);
  }
  onBgChange(event): void {
    this.cookieService.set('bgImg', event.value);
    this.changeBackground(event.value);
  }
  onColorChange(id, event): void {
    if (id === '--navbarTitleColor') {
      this.global.navbarTitleColor = event;
    } else if (id === '--navbarTitleShadowColor') {
      this.global.navbarTitleShadowColor = event;
    }
    document.body.style.setProperty(id, event);
  }
  onNumberChange(id, val): void {
    document.body.style.setProperty(id, val + 'px');
  }

  changeBackground(bg) {
    const tws = document.body.style;
    switch (bg) {
      case 'none':
        tws.setProperty('--bgImage', 'none');
        break;
      case 'funky-lines':
        tws.setProperty('--bgImage', 'url("/assets/img/funky-lines.png")');
        break;
      case 'qbkls':
        tws.setProperty('--bgImage', 'url("/assets/img/qbkls.png")');
        break;
      case 'dark-triangles':
        tws.setProperty('--bgImage', 'url("/assets/img/dark-triangles.png")');
        break;
      case 'dust-scratches':
        tws.setProperty('--bgImage', 'url("/assets/img/dust-scratches.png")');
        break;
      case 'footer-lodyas':
        tws.setProperty('--bgImage', 'url("/assets/img/footer-lodyas.png")');
        break;
      case 'sayagata':
        tws.setProperty('--bgImage', 'url("/assets/img/sayagata.png")');
        break;
      case 'small-steps':
        tws.setProperty('--bgImage', 'url("/assets/img/small-steps.png")');
        break;
      case 'what-the-hex-dark':
        tws.setProperty(
            '--bgImage', 'url("/assets/img/what-the-hex-dark.png")');
        break;
    }
  }

  globalOverride(stylesheet) {
    theme_override(stylesheet, document.body, this.cookieService);
    this.styleUpdate();
  }
}
