import {Component, AfterViewInit, ElementRef} from '@angular/core';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemsComponent implements AfterViewInit {
  constructor() {}

  // @ViewChild('nes') sentinelRef: ElementRef;
  // @ViewChild('neh') headerRef: ElementRef;
  // @ViewChild('nt') navbarTitleRef: ElementRef;
  // @ViewChild('altlogo') altLogoRef: ElementRef;


  ngAfterViewInit() {}
}