import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/components/common/selectitem';
import {SelectItemGroup} from 'primeng/components/common/selectitemgroup';

export const styleColors = [
  // Fonts
  '--fontFamily',
  '--titleFontFamily',
  // Navbar
  '--navbarTitleColor',
  '--navbarTitleShadowColor',
  '--navbarAltTitleColor',
  '--navbarTopColor',
  '--navbarBottomColor',
  '--navLinkColor',
  '--navActiveLinkColor',
  // General
  '--linkColor',
  '--primaryColor',
  '--secondaryColor',
  '--textPrimaryColor',
  '--textSecondaryColor',
  // Footer
  '--footerBgColor',
  // Shadowbox
  '--sbColor',
];

export const styleNumbers = [
  // Shadowbox
  '--sbSpread',
  '--sbBlur',
  '--sbV',
  '--sbH',
];

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  static readonly defaultBgImage = 'funky-lines';
  static readonly defaultIsColorTransition = true;
  static readonly defaultIsContainer = false;
  static readonly defaultIsShadowbox = false;
  static readonly defaultIsShadowboxInset = false;
  static readonly defaultIsNavDecor = true;

  static readonly defaultIsVidHome = false;
  isVidHome: boolean = GlobalService.defaultIsVidHome;

  navbarTitleColor = '#000';
  navbarTitleShadowColor = '#000';
  isColorTransition: boolean = GlobalService.defaultIsColorTransition;
  isContainer: boolean = GlobalService.defaultIsContainer;
  isShadowboxInset: boolean = GlobalService.defaultIsShadowboxInset;
  isShadowbox: boolean = GlobalService.defaultIsShadowbox;
  isNavDecor: boolean = GlobalService.defaultIsNavDecor;

  // DEV
  isDebug = false;

  constructor() {}

  themes: SelectItem[] = [
    // {label: 'Select Theme', value: null},
    // {label: 'Red', value: 'style1'},            // red
    // {label: 'Orange', value: 'style2'},         // orange - dark
    // {label: 'Purple', value: 'style3'},         // purple
    {label: 'Muted Tones', value: 'style4'},  // Muted Tones
    // {label: 'Warm and Bold', value: 'style5'},  // Warm and Bold
    {label: 'Asiimov', value: 'style6'},  // Warm and Bold
    {label: 'ESAB', value: 'style7'},     // Warm and Bold
  ];

  sep = '~ ';

  bgs: SelectItemGroup[] = [
    {
      label: this.sep + 'Other',
      items: [
        {label: 'None', value: 'none'},
      ]
    },
    {
      label: this.sep + 'Light',
      items: [
        {label: 'Funky Lines', value: 'funky-lines'},
        {label: 'QBKLS', value: 'qbkls'},
        {label: 'dust-scratches', value: 'dust-scratches'},
        {label: 'sayagata', value: 'sayagata'},
        {label: 'small-steps', value: 'small-steps'},
      ]
    },
    {
      label: this.sep + 'Dark',
      items: [
        {label: 'dark-triangles', value: 'dark-triangles'},
        {label: 'footer-lodyas', value: 'footer-lodyas'},
        {label: 'what-the-hex-dark', value: 'what-the-hex-dark'},
      ]
    }
  ];
}
