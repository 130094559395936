import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';

import {GlobalService} from 'src/app/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit,
    AfterViewInit {
  constructor(public global: GlobalService) {}

  @ViewChild('vid', {static: true}) vidRef: ElementRef;

  asd() {
    this.vidRef.nativeElement.muted = 'muted';
    this.vidRef.nativeElement.play();
  }

  ngAfterViewInit() {
    // this.vidRef.nativeElement.onloadeddata = function() {
    //   this.vidRef.nativeElement.play();
    // };
    // setTimeout(() => { this.vidRef.nativeElement.play(); }, 150);
  }

  ngOnInit() {}
}
